import React, {useEffect, useState} from "react";
import {Row, Col} from "antd";
import "./style.css";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import icon from '../../statics/media/lz.5e1afaf4.jpg';
import QRcode from '../../statics/foot/QRcode.png';
import ico_email from '../../statics/foot/ico_email.svg';
import ico_location from '../../statics/foot/ico_location.svg';
import ico_phone from '../../statics/foot/ico_phone.svg';
import mobxstore from "../../mobxStore/store";
import {reaction} from "mobx";

function Footer() {
    moment().format("YYYY")
    // let currentYear = moment().year(); // Number
    const [menuList, setMenuList] = useState([])

    useEffect(() => {
        reaction(() => mobxstore.menuList,
            () => {
                setMenuList(mobxstore.menuList)
            }
        )

    }, [])

    return (
        <footer id="footer" className="dark">
            <div key="a">
                <div className="footer-wrap">
                    <Row justify="center" key="c_1">
                        <Col lg={2} sm={24} xs={24}/>
                        <Col lg={3} sm={24} xs={24}>
                            <div
                                style={{
                                    cursor: 'pointer',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    textAlign: 'start'
                                }}>
                                {/*<Link to="/index">首页</Link>*/}
                            </div>

                        </Col>
                    </Row>
                    <Row justify="center" key="c_2">
                        <Col lg={2} sm={24} xs={24}/>
                    </Row>
                    <Row justify="center" key="c_3">
                        <Col lg={3} sm={24} xs={24}/>
                        <Col lg={12} sm={24} xs={24}>
                            <div className="footer-center">
                                <div className="footer-content">
                                    <span style={{
                                        color: 'rgba(169,217,20,1)'
                                    }}>
                                      Let’s collaborate together.
                                                                </span>
                                    <br/>
                                    <span>
                                      Find out how your business can contribute to a sustainable
                                      environment.
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Row className="bottom-bar" >
                    <Col lg={3} sm={2} xs={2}/>
                    <Col lg={12} sm={12}>
                        <div className='footer-tail'>
                            <div style={{display:'flex'}}>
                                <div>© 2024 PlusRise</div>
                                <div style={{marginLeft:'110px',textAlign:'start'}}>
                                    {/*<div>Imprint</div>*/}
                                    {/*<div>Data Privacy</div>*/}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </footer>
    );
}

export default withRouter(Footer);
