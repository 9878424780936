import React, {Component} from 'react';
import './style.css';
import Vector from '../../statics/plusrise/Vector.png';
import Vector1 from '../../statics/plusrise/Vector1.png';
import Vector2 from '../../statics/plusrise/Vector2.png';
class AnimationIcon extends Component {
    render() {
        return (
            <div className='hover-box'>
                <div className='icon-box' >
                    <img src={Vector} style={{width:'20px',position:'absolute',left:0}}/>
                    <img src={Vector1} className='imgOpacity' style={{width:'20px',position:'absolute',right:0}}/>
                    <img src={Vector2} style={{width:'20px',position:'absolute',bottom:0,right:0}}/>
                </div>
            </div>
        );
    }
}

export default AnimationIcon;