import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Avatar, Button, Col, Menu, Row, Icon} from "antd";
import "./style.css";
import logoqdcloud from "../../statics/index/logo_qdcloud.svg";
import {fetchLaunchedSolutionList} from '../../actions/fetchDataAction';
import mobxstore from "../../mobxStore/store";
import AnimationIcon from "../animationIcon";
import {reaction} from "mobx";

class Head extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            rerender: false,
            activeMenu: '',
            offset: 40 //偏移量
        }
    }

    componentDidMount() {
        const {offset} = this.state
        const header = document.querySelector('.ant-layout-header')

        // 根据顶部距离调整head的不透明度
        // window.addEventListener('scroll', () => {
        //     const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //     let opacity = scrollTop / 1000 + 0.25
        //     header.style.backgroundColor = `rgba(5, 44, 56,${opacity})`
        //
        // });


        let dom1 = document.querySelector('.program')
        let dom2 = document.querySelector('.contact')

        // 添加滚动事件监听器
        window.addEventListener('scroll', () => {
            if (dom1 && dom2) {
                // 在这里执行滚动事件发生时的操作
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                if (scrollTop+offset >= dom1.offsetTop && scrollTop+offset < dom2.offsetTop) {
                    this.setState({activeMenu: 'program'})
                } else if (scrollTop+offset >= dom2.offsetTop) {
                    this.setState({activeMenu: 'contact'})
                } else {
                    this.setState({activeMenu: ''})
                }
            } else {
                dom1 = document.querySelector('.program')
                dom2 = document.querySelector('.contact')
            }

        });
    }

    moveScroll = ({className}) => {
        const {offset} = this.state
        let dom = document.querySelector(`.${className}`)

        window.scrollTo({
            top: dom.offsetTop - offset,
            behavior: 'smooth'  // 可选的平滑滚动效果
        });

        // if(this.isMobileDevice()){
        //     mobxstore.openModal()//显示弹窗
        // }
    }

    //判断设备是手机
    isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    render() {
        const {SubMenu} = Menu;
        const {menu} = this.state

        let isLogged = window.sessionStorage.getItem('isLogged')
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <div className="logo">
                            <Link to="/index">
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <AnimationIcon></AnimationIcon>
                                </div>
                            </Link>
                        </div>
                        <Menu
                            theme="light"
                            mode="horizontal"
                            defaultSelectedKeys={[this.props.history.location.pathname]}
                            selectedKeys={[this.props.history.location.pathname]}
                            style={{
                                lineHeight: "80px",
                                float: 'right',
                                fontSize: '0.2rem',
                                fontWeight: 600,
                                position: 'relative'
                            }}
                        >
                            {/*<Menu.Item key="/access">*/}
                            {/*  <Link to="/access">接入指南</Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key="/myproject">{isLogged?<Link to="/myproject">我的项目</Link>:<Link to="/login">我的项目</Link>}</Menu.Item>*/}
                            {/*{sessionStorage.getItem('roleTypeId') ?*/}
                            {/*    <Menu.Item key="/projectManager">*/}
                            {/*        <Link to="/projectManager">项目管理</Link>*/}
                            {/*    </Menu.Item> : ''}*/}

                            <Menu.Item
                                className={this.state.activeMenu == 'program' ? 'btn underline' : 'btn'}
                                onClick={() => {
                                    this.moveScroll({className: 'program'})
                                    // this.setState({activeMenu:'program'})
                                }}
                            >
                                About PlusRise
                            </Menu.Item>

                            <Menu.Item
                                className={this.state.activeMenu == 'contact' ? 'btn underline' : 'btn'}
                                onClick={() => {
                                    mobxstore.openModal()
                                    // this.moveScroll({className: 'contact'})
                                    // this.setState({activeMenu:'contact'})
                                }}
                            >
                                Contact
                            </Menu.Item>

                            {/*<Menu.Item className="btn"*/}
                            {/*           style={{marginLeft: '2.5rem', marginRight: isLogged ? '1rem' : '1.5rem'}}>*/}
                            {/*    {isLogged ?*/}
                            {/*        <Link style={{textDecoration: 'none'}} to={{pathname: "/myaccount", search: "id="}}>*/}
                            {/*            <Avatar size="large" icon="user"/>*/}
                            {/*        </Link> : (*/}
                            {/*            <>*/}
                            {/*                <Link to="/login">登录</Link>*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*</Menu.Item>*/}

                            {/*{isLogged ? '' : <Menu.Item style={{marginRight: '1.5rem'}}>|</Menu.Item>}*/}
                            {/*{isLogged ? (*/}
                            {/*    <SubMenu*/}
                            {/*        title={*/}
                            {/*            <span className="submenu-title-wrapper">*/}
                            {/*    ▼*/}
                            {/*    </span>*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        <Menu.Item key="/register" className="btn">*/}
                            {/*            <Link to="/" onClick={() => {*/}
                            {/*                sessionStorage.removeItem("isLogged")*/}
                            {/*                sessionStorage.removeItem('token')*/}
                            {/*                sessionStorage.removeItem('roleTypeId')*/}
                            {/*            }*/}
                            {/*            }>退出</Link>*/}


                            {/*        </Menu.Item>*/}


                            {/*        {sessionStorage.getItem('roleTypeId') ? (*/}
                            {/*            <Menu.Item key="/projectManage" className="btn">*/}
                            {/*            <span onClick={() => {*/}
                            {/*                window.open("http://122.112.170.70:6002/"); //跳转到后台*/}
                            {/*            }}>后台管理</span>*/}
                            {/*            </Menu.Item>*/}
                            {/*        ) : ''}*/}


                            {/*    </SubMenu>*/}
                            {/*) : <Menu.Item key="/register" className="btn"><Link to="/register">注册</Link></Menu.Item>}*/}

                        </Menu>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Head);
