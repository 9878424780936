import React from 'react';
import wallpaper1 from "../../statics/plusrise/wallpaper1.png";
import wallpaper2 from "../../statics/plusrise/wallpaper2.png";
import wallpaper3 from "../../statics/plusrise/wallpaper3.png";
import './style.css'

const AnimationIconLarge = () => {
  return (
      <div className='hover-box-l'>
          <div className='icon-box-l' >
              <img src={wallpaper2} className='icon-width' style={{position:'absolute',left:0,zIndex:100,top:0}}/>
              <img src={wallpaper3} className='icon-width' style={{position:'absolute',right:'-1vw',top:'-0.5vw'}}/>
              <img src={wallpaper1} className='icon-width' style={{position:'absolute',bottom:0,right:0}}/>
          </div>
      </div>
  );
}

export default AnimationIconLarge;
