import React, {Component} from 'react';
import './style.css';
import {withRouter} from 'react-router-dom';
import headline from '../../statics/plusrise/headline.png';
import AnimationIconLarge from "../../common/animationIconLarge";
import mobxstore from "../../mobxStore/store";

class Index extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // 获取完整的 URL
        const url = window.location.href;

        // 从 URL 中提取查询参数部分
        const queryString = url.split('?')[1];

        // 解析查询参数
        const params = new URLSearchParams(queryString);

        // 获取特定参数的值
        const content = params.get('content');

        if(content){
            mobxstore.openModal()
        }

    }

    render() {
        return (
            <div>
                <div className='background-banner'>
                    <div className='font-position'>
                        <div className='green-font' style={{color: '#a9d914'}}>
                            Coming soon!
                        </div>
                        <img className='img' src={headline}/>
                        <div className="impact">Connecting for impact.</div>
                    </div>
                    <div className='large-icon'><AnimationIconLarge></AnimationIconLarge></div>
                </div>

                <div className="banner2-title program">
                    The <br/>
                    program
                </div>
                <div className='background-banner2 '>
                    <div>
                        <div className='box1'>
                            Empowering Sustainable Innovation
                        </div>
                    </div>


                    <div className='plus-rise'>
                        <div className='box2'>
                            What is PlusRise?
                        </div>

                        PlusRise is a collaborative platform that <br/>
                        empowers European and Chinese <br/>
                        companies to drive&nbsp;
                        <span>sustainable innovation
                        </span>. <br/>
                        By fostering cross-border collaboration and <br/>
                        knowledge exchange, PlusRise enables <br/>
                        businesses to&nbsp;
                        <span>
                          integrate eco-friendly
                        </span>
                        <br/>
                        practices into their operations, promoting <br/>
                        environmental stewardship and resilience in <br/>
                        an ever-changing market landscape.
                    </div>

                </div>


                <div className='background-banner2 contact flex-box'>
                    <div className="banner2-title" id="contact">
                        Contact
                    </div>
                    <div className="munich">
                        <div className="munich-2">Munich Office</div>
                        <div className="munich-3">
                      <span style={{whiteSpaceCollapse: 'preserve'}}>
                        {/*N+P Innovation Design GmbH <br/>*/}
                        Oskar-von-Miller-Ring 34<br/>
                          80333 Munich /
                        Germany
                        <br/>
                        <div style={{marginTop:'10px'}}></div>
                        +49 89 2109620
                        <br/>
                        munich@np-id.com
                      </span>
                        </div>
                    </div>

                    <div className="shanghai">
                        <div className="shanghai-2">Shanghai Office</div>
                        <div className="shanghai-3">
                      <span style={{whiteSpaceCollapse: 'preserve'}}>
                            819 Nanjing West Road, 3F-153
                          <br/>
                            Jingan District, Shanghai 200041
                        <br/>
                        <div style={{marginTop:'10px'}}></div>
                        +86 138 1073 9553
                        <br/>
                        info@circle.do
                      </span>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(Index)