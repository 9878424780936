import React, {Component} from 'react';
import './style.css'
import mobxstore from "../../mobxStore/store";
import {pwCreateContact} from '../../../src/actions/postAction'
import {Alert, Space} from 'antd';
import close from '../../statics/plusrise/close-line.png'
class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false
        }
    }

    handleSendBtn = () => {
        let email = this.emailInput.value
        let message = this.textAreaInput.value
        if (email && message) {
            //发送反馈
            pwCreateContact(email, message).then((res) => {
                if (res.status == 200) {
                    this.setState({sent: true})
                    // mobxstore.closeModal()
                    // mobxstore.openAlert()
                    // setTimeout(()=>{
                    //     mobxstore.closeAlert()
                    // },3000)
                }
            })
        }

    }

    render() {
        const {sent} = this.state
        return (
            <div style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.25)',
                zIndex: 999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div className='modal-box'>
                    <div className='close-icon' onClick={() => {
                        mobxstore.closeModal()
                        this.setState({sent: false})
                    }}>
                        <img src={close} style={{width:'25px',height:'25px'}} />
                    </div>
                    <div className='contact'>{sent ? 'Thank you!' : 'Contact us!'}</div>

                    {sent ? <span style={{fontSize:'12px'}}>We will get back to you soon.</span> : (
                        <>
                            <div className='title'>E-mail</div>
                            <input type="email" ref={(e) => this.emailInput = e} className='email-input'/>

                            <div className='title'>Message</div>
                            <textarea ref={(e) => this.textAreaInput = e} className='textArea'></textarea>
                            <div className='send-btn' onClick={() => {
                                this.handleSendBtn()
                            }}>Send
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Modal;