import {observable, configure, action} from "mobx";

//开启严格模式，其他地方去修改store里的值会报错
configure({
    enforceActions: "always",
});
const mobxstore = observable(
    {
        showModal: false,
        showAlert: false,
        list: [],
        projectType: [],
        launchedSolutionList: [],
        menuList: [],
        setMenuList(itemList) {
            this.menuList = itemList
        },
        setProjectType(projectTypeValues) {
            this.projectType = observable(projectTypeValues);
        },
        setLaunchedSolutionList(value) {
            this.launchedSolutionList = value
        },
        openModal() {
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        openAlert() {
            this.showAlert = true
        },
        closeAlert() {
            this.showAlert = false
        }

    },
    {
        setProjectType: action,
        setLaunchedSolutionList: action,
        setMenuList: action,
        openModal: action,
        closeModal: action,
        openAlert:action,
        closeAlert:action
    }
);

export default mobxstore;
