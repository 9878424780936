import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { Layout,Alert } from "antd";
import Head from "./common/header";
import Foot from "./common/footer";
import Index from "./pages/index";

import ScrollToTop from "../src/component/ScrollToTop/index";
import "./app.css";
import "./antd-theme/index.css"
import config from './config'
import Modal from './common/modal'
import mobxstore from "./mobxStore/store";
import {reaction} from "mobx";
import { observer } from 'mobx-react';

const { Header, Footer, Content } = Layout;

class App extends Component {

  constructor(props) {
    super(props);
    this.state={
      rerender:false
    }
  }

  componentDidMount() {
    this.sub=reaction(
        ()=>mobxstore.showModal,
        ()=>{
          this.setState({rerender:!this.state.rerender})
        }
    )
  }

  render() {
    return (
      <Router>
        <Layout>
          <Header className="header">
            <Head/>
          </Header>
          <Content className="header">
            {
              mobxstore.showAlert?(
                  <div style={{width:'250px',position:'absolute',height:'100px',zIndex:9999,right:'50px',top:'150px'}}>
                    <Alert message="send Success" type="success" showIcon closable />
                  </div>
              ):''
            }
            {mobxstore.showModal?<Modal></Modal>:''}
            <ScrollToTop>
            <Switch>
              <Route path="/index" exact component={Index} />
              <Route
                path="/"
                exact
                render={() => <Redirect to="/index" push />}
              />

              {/*{menu&&menu.map((route, index) => (*/}
              {/*    <Route key={index} path={route.routeUrl} component={route.component} />*/}
              {/*))}*/}
            </Switch>
            </ScrollToTop>
          </Content>
          <Footer className="footer">
            <Foot />
          </Footer>
        </Layout>
      </Router>
    );
  }
}

export default observer(App);
