import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://www.qdcloud.net:8443/rest/services/',  // 设置基本URL（根据你的API地址进行修改）
    timeout: 5000,  // 超时时间（根据需要进行修改）
});

// 请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 可以在这里修改请求配置，例如添加请求头
        if (!navigator.onLine) {
            // 没有网络连接的处理逻辑
            console.log('请检查网络连接')

        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


export default instance;